<template>
  <div class="max-w-screen-mobile mx-auto h-screen  py-2 text-center realtive">
    <Header :lang="lang" />
    <div class="text-center mx-6">
      <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white">{{ $t('language.selectTitle') }}</h4>
      <Language flag mobile :language="lang" @langchanged="langchanged" class="my-4"/>
      <p class="font-body text-bodyL text-sepiaBlack dark:text-white px-6">{{ $t('language.text') }}</p>
    </div>
    <Button href="/mobile/menu" :text="$t('language.saveBtn')" buttonType="primary" fullWidth class="absolute bottom-4 inset-x-12"/>
  </div>
</template>

<script>

import Language from '@/components/Language.vue';
import Button from '@/components/Button.vue';
import Header from "@/components/mobile/Header.vue";

export default {
  name: 'LanguageMobile',
  components: {
    Language,
    Button,
    Header
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro'
    }
  },
  methods: {
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        return require("../assets/logo_dark.png");
      } else {
        return require("../assets/logo_light.png");
      }
    },
    langchanged(value) {
        this.lang = value;
      },
  }
}
</script>
